<template>
 <div class="v_my_invite g-flex-column">
   <head-back :isBack="true">
    <template>
      <span>我的邀请码</span>
    </template>
  </head-back>
  <div class="v-my-invite-content">
    <div class="v-my-invite-code-container">
      <div class="v-my-invite-code g-flex-align-center">
        <span class="v-my-invite-code-title">我的邀请码:</span>
        <p class="v-my-invite-code-val">{{userInfo.spread_code}}</p>
        <div class="v-my-invite-code-copy-btn" @click="copyClick(userInfo.spread_code)">复制</div>
      </div>
      <div class="v-my-invite-store-code g-flex-column">
        <span class="v-my-invite-store-code-title">商家推广邀请:</span>
        <p class="v-my-invite-store-code-val">{{userInfo.shop_spread}}</p>
        <div class="v-my-invite-code-copy-btn" @click="copyClick(userInfo.shop_spread)">复制</div>
      </div>  
    </div>

  </div>
 </div>
</template>

<script>
import HeadBack from '@/components/HeadBack.vue'
export default {
  components: { HeadBack },
  data() {
    return {
    }
  },
  computed: {
    userInfo() {
      return this.$global.userInfo
    } 
  },
  methods: {
    copyClick(message) {
      this.$copyText(message).then(() => {
        this.$toast({
          type: 'success',
          message: '复制成功'
        });
      },  () => {
        this.$toast('复制失败,请重试');
      })
    },
  }
}
</script>

<style lang='scss'>
.v_my_invite {
  height: 100%;
  overflow: auto;
  .v-my-invite-content {
    flex: 1;
    background: #F1F1F1;
    padding: 8px 10px;
    .v-my-invite-code-container {
      background: $white;
      border-radius: 8px;
      padding: 18px 20px;
      .v-my-invite-code-copy-btn {
        width: 54px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 12px;
        color: $main_color;
        border: 1px solid $main_color;
        background: $white;
        border-radius: 12px;
      }
      .v-my-invite-code {
        padding-bottom: 16px;
        border-bottom: 1px solid $main_color;
        .v-my-invite-code-title {
          font-size: 13px;
          color: #020202;
        }
        .v-my-invite-code-val {
          color: $main_color;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;
        }
      }
      .v-my-invite-store-code {
        padding-top: 16px;
        .v-my-invite-store-code-title {
          font-size: 13px;
          color: #020202;
        }
        .v-my-invite-store-code-val {
          align-self: stretch;
          font-size: 14px;
          color: $main_color;
          padding-top: 14px;
          padding-bottom: 14px;
        }
      }
    }
  }
}
</style>